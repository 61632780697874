import React from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { usePreview } from '../hooks/usePreview'

import { Page } from '../components/page'
import { Project } from '../components/project'

function NotFound() {
  const { pathname } = useLocation(),
    { previewData, path } = usePreview()
  let children = []

  // Render previews for unpublished content
  if (pathname === path) {
    if (previewData?.prismicProject)
      children.push(<Project project={previewData.prismicProject} />)
    if (previewData?.prismicPage)
      children.push(<Page page={previewData.prismicPage} />)
  }

  return children.length > 0 ? (
    children
  ) : (
    <div className="min-h-screen py-24 lg:px-24 flex flex-col items-center justify-center">
      <Link to="/" className="text-6xl">
        <em>404:</em>
        <span className="not-italic"> Let's go home</span>
      </Link>
    </div>
  )
}

export default NotFound
